<template>
  <div class=" mt-4 mx-2">
    <div class="mb-1">
      <b-button
        variant="primary"
        class=""
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="$router.push({ name: 'freelance' })"
      >Go Back</b-button>
    </div>
    <b-overlay
      :show="is_loading"
      rounded="sm"
    >
      <b-row>
        <!-- Left Section: Resume -->
        <b-col md="6">
          <b-card class="h-100">
            <h5 class="text-center">
              Resume
            </h5>
            <iframe
              :src="resume_file_url"
              width="100%"
              height="100%"
              frameborder="0"
            />
          </b-card>
        </b-col>

        <!-- Right Section: User Info -->
        <b-col md="6">
          <b-card class="h-100">
            <div class="d-flex justify-content-between">
              <!-- Header Name (User Name Bold) -->
              <div>
                <h3 class="font-weight-bold">
                  {{ fullName }}
                </h3>

                <!-- Industries as Badges -->
                <div class="mb-3">
                  <b-badge
                    variant="warning"
                    class="mr-2"
                  >
                    {{ industry }}
                  </b-badge>
                </div>
              </div>

              <div>
                <span class="mr-2"> ${{ pricing }}</span>
                <!-- Engage Button and Pricing -->
                <b-button
                  variant="primary"
                  class="mr-2"
                >
                  <feather-icon name="message-square" /> Engage
                </b-button>

              </div>
            </div>
            <!-- Divider -->
            <hr>

            <!-- Description -->
            <!-- <h5>Description</h5> -->
            <p>{{ description }}</p>

            <!-- Portfolio/Case Studies Section -->
            <div>
              <h5>
                <b>Work Portfolios</b>
              </h5>

              <!-- Show Portfolio Images -->

              <b-carousel
                v-if="portfolios && portfolios.length > 0"
                id="carousel-caption"
                controls
                indicators
                img-width="600"
                img-height="200"
              >
                <!-- First Slide: Portfolio 1 -->
                <b-carousel-slide
                  v-for="(portfolio, index) in portfolios"
                  :key="index"
                  class="card-img-overlay"
                  style="max-height: 300px!important;"
                  :text="portfolio.description"
                  :img-src="portfolio.image_url"
                />
              </b-carousel>

              <h5 class="mt-3">
                <b>Case Studies</b>
              </h5>
              <!-- Show Case Studies -->
              <b-row v-if="caseStudies && caseStudies.length">
                <b-col
                  v-for="(caseStudy, index) in caseStudies"
                  :key="index"
                  cols="12"
                  class="mb-4"
                >
                  <b-card>
                    <h5><b>{{ caseStudy.title }}</b></h5>
                    <p style="white-space: pre-wrap;">
                      {{ caseStudy.description }}
                    </p>
                  </b-card>
                </b-col>
              </b-row>

            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>

export default {
  data() {
    return {
      // User information
      is_loading: false,
      fullName: null,
      industry: null,
      pricing: null,
      description: null,

      // Portfolios
      portfolios: [

      ],

      // Case Studies
      caseStudies: [

      ],
      resume_file_url: null,
    }
  },

  created() {
    const freelancerID = this.$route.params.freelancer_id
    if (freelancerID) {
      this.getFreelanceDetails(freelancerID)
    } else {
      this.$router.push({ name: 'freelance' })
    }
  },
  methods: {
    getFreelanceDetails(freelancerID) {
      this.is_loading = true
      this.$http.get(
        `/api/freelancer/user/${freelancerID}?public=true`,

      ).then(response => {
        const { success, post } = response.data
        if (success) {
          const freelance_profile = post.FreelanceProfile
          this.fullName = post.fullname
          this.industry = freelance_profile.industry
          if (freelance_profile.price_on_enquiry) {
            this.pricing = 'Price On Enquiry'
          } else {
            this.pricing = freelance_profile.pricing
          }
          this.description = freelance_profile.description
          this.resume_file_url = `https://docs.google.com/gview?url=${encodeURIComponent(post.resume_file_url)}&embedded=true&random=${new Date().getTime()}`

          this.portfolios = post.portfolios
          this.caseStudies = post.case_studies
        }

        this.is_loading = false
      })
        .catch(error => {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')

          this.is_loading = false
        })
    },
  },
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
